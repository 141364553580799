import * as React from "react"
import Frame from "../components/frame";
import {Link} from "gatsby";

export default function NotFound() {
    return (
        <Frame>
            <header>
                <div className="container">
                    <h1> Page not found </h1>
                </div>
            </header>
            <section>
                <div className="container">
                    <p className="404-message">
                        Either it's an old link, or you are tinkering around the site.
                        <br/>
                        Anyway, here are the do-found pages:
                    </p>
                    <div className="404-link">
                        <Link to="/">
                            Home
                        </Link>
                    </div>
                    <div className="404-link">
                        <Link to="/about/">
                            About
                        </Link>
                    </div>
                </div>
            </section>
        </Frame>
    )
}
